import React from 'react';
import SocialItem from '../social-item';

import './social-list.scss';

const SocialList = ({data}) => {
  const items = data.map((it, i) => <SocialItem data={it} key={`social-${i}`}/>);
  return <ul className="social-networks">{ items }</ul>;
}

export default SocialList;