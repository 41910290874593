import SnackbarWrapper from "../snackbarWrapper/snackbarWrapper";
import SnackbarProvider from "react-simple-snackbar";
// const CharactersViewport = handleViewport(Characters);

const App = () => {
  return (
    <div className="wrapper">
      <SnackbarProvider>
        <SnackbarWrapper />
      </SnackbarProvider>
    </div>
  );
};

export default App;
