import React from 'react';
import FooterNav from '../footer-nav';
import Logo from '../logo';

import './footer.scss';

import AsunaService from '../../services/asuna-service';

const Footer = () => {
  const { footerNav, logoImage, privacy } = new AsunaService().getFooterData();

  return(
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="column">
            <Logo data={logoImage} />
          </div>
          <FooterNav data={footerNav}/>
        </div>
        <div className="privacy">
          <p>{ privacy }</p>
        </div>
      </div>
    </footer>
    );
}

export default Footer;