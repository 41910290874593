import React, { useState, useEffect } from "react";
import Button from "../button";

import "./counter.scss";
import axios from "axios";
import AsunaService from "../../services/asuna-service";
import { ethers, utils } from "ethers";

const PRESALE = true;

const Counter = ({
  maxQuantity = 20,
  address,
  mintWhitelist,
  mint,
  didMint,
}) => {
  const [count, setCount] = useState(1);
  const { title, paragraph1, paragraph2, btnName } =
    new AsunaService().getCounterData();

  const [didFetch, setDidFetch] = useState(false);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (!didFetch) {
      refreshData();
    }
  }, [didFetch]);

  const refreshData = async () => {
    try {
      // /api/isWhitelisted
      const response = await axios.get(`/api/isWhitelisted?address=${address}`);
      setIsWhitelisted(response.data.isWhitelisted);
      setAmount(response.data.amount);
    } catch (error) {
      console.log(error);
    }

    setDidFetch(true);
  };

  const onChangeCount = (e, sign) => {
    e.preventDefault();
    const newCount =
      sign === "plus" && count < maxQuantity
        ? count + 1
        : sign === "minus" && count > 0
        ? count - 1
        : null;
    if (newCount === null) return;
    setCount(Math.max(Math.min(newCount, amount), 1));
  };

  const onChangeCountPublic = (e, sign) => {
    e.preventDefault();
    const newCount =
      sign === "plus" && count < maxQuantity
        ? count + 1
        : sign === "minus" && count > 0
        ? count - 1
        : null;
    if (newCount === null) return;
    setCount(Math.max(Math.min(newCount, 1), 1));
  };

  if (!didFetch) {
    return (
      <div className="counterWrap" id="counter-section">
        <p>{`Checking raffle info...`}</p>
      </div>
    );
  }

  if (didMint) {
    return (
      <div className="counterWrap" id="counter-section">
        <p>{`Congratulations on minting! Your mint is now processing.`}</p>
      </div>
    );
  }

  if (PRESALE) {
    return (
      <div className="counterWrap" id="counter-section">
        <span className="counter-title">welcome, {address}</span>
        {isWhitelisted && (
          <>
            <p>{`Congratulations, you have been selected as a winner!`}</p>
            <br />
            <p>{`You will be able to mint 1 NFT in total.`}</p>
            <br />
            <p>
              Please keep track of this yourself - exceeding this limit will
              cause your transactions to fail.
            </p>
            <br />
            {/* <span className="counter-title">{title}</span> */}
            <div className="counter-holder">
              {/* <a
                className="counter-btn btn-prev"
                href="/"
                onClick={(e) => onChangeCount(e, "minus")}
              >
                <i className="icon icon-minus"></i>
              </a>
              <span className="count">{count}</span>
              <a
                className="counter-btn btn-next"
                href="/"
                onClick={(e) => onChangeCount(e, "plus")}
              >
                <i className="icon icon-plus"></i>
              </a> */}
            </div>
            <Button
              className="btn btn-decorated"
              onClickHandler={() => mintWhitelist(count)}
            >
              {btnName}
            </Button>
            <p>0.2 ETH per NFT</p>
          </>
        )}
        {!isWhitelisted && (
          <p>{`Sorry, you are not on the whitelist at this time.`}</p>
        )}
      </div>
    );
  }

  if (!PRESALE) {
    return (
      <div className="counterWrap" id="counter-section">
        <span className="counter-title">welcome, {address}</span>
        {isWhitelisted && (
          <>
            <p>{`The public sale has begun!`}</p>
            <br />
            <p>
              We are extremely over subscribed so please be careful with your
              transactions!
            </p>
            <br />
            <p>You may mint 1 NFT.</p>
            <br />
            {/* <span className="counter-title">{title}</span>
            <div className="counter-holder">
              <a
                className="counter-btn btn-prev"
                href="/"
                onClick={(e) => onChangeCountPublic(e, "minus")}
              >
                <i className="icon icon-minus"></i>
              </a>
              <span className="count">{count}</span>
              <a
                className="counter-btn btn-next"
                href="/"
                onClick={(e) => onChangeCountPublic(e, "plus")}
              >
                <i className="icon icon-plus"></i>
              </a>
            </div> */}
            <Button
              className="btn btn-decorated"
              onClickHandler={() => mint(count)}
            >
              {btnName}
            </Button>
            <p>{paragraph1}</p>
          </>
        )}
        {!isWhitelisted && (
          <p>{`Sorry, you are not on the whitelist at this time. We are updating the whitelist periodically with more addresses so please check again later!`}</p>
        )}
      </div>
    );
  }
};

export default Counter;
