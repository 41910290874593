import React from 'react';
import AnchorNavItem from '../anchor-nav-item';

import './anchor-nav.scss';

const AnchorNav = ({data, onAnchorLinkClickHandler}) => {
  const items = data.map((it) => <AnchorNavItem onClickHandler={onAnchorLinkClickHandler} key={`nav-item-${it}`} data={it} />);
  return(
    <nav className="nav-holder">
      <ul className="nav-main">
        { items }
      </ul>
    </nav>
  );
};

export default AnchorNav;