import React from 'react';

import TeamList from '../team-list';
import TextBlock from '../text-block';
import handleViewport from "react-in-viewport";

import './team-section.scss';

import AsunaService from '../../services/asuna-service';

const TextBlockViewport = handleViewport(TextBlock);
const TeamListViewport = handleViewport(TeamList);

const TeamSection = () => {
  const { textBlock, teamList } = new AsunaService().getTeamSectionData();
  return(
    <div id="team" className="team-section">
      <div className="container">
        <TextBlockViewport data={textBlock}/>
        <TeamListViewport data={teamList}/>
      </div>
    </div>
    );
}

export default TeamSection;