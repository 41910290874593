import React from 'react';
import TitleCustom from '../title-custom';
import InfoBlock from '../info-block';

import './about-section.scss';

import AsunaService from '../../services/asuna-service';

const AboutSection = ({ enterCount, innerRef }) => {
  const classes = `about-section ${enterCount !== 0 ? 'shown' : ''}`;
  const { mainTitle, infoBlock } = new AsunaService().getAboutSectionData();
  return(
    <section id="about" className={classes} ref={innerRef}>
      <div className="container">
        <TitleCustom>{mainTitle}</TitleCustom>
        <InfoBlock data={infoBlock}/>
      </div>
    </section>
  );
}

export default AboutSection;