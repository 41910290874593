import React from 'react';
import FooterNavItem from '../footer-nav-item';

import './footer-nav.scss';

const FooterNavList = ({data}) => {
  const items = data.map((it) => <FooterNavItem data={it} key={`footer-nav-item-${it.title}`}/>);
  return <div className="content-column">{ items }</div>;
}

export default FooterNavList;