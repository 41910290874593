import React from 'react';

import './character.scss';

const Character = ({ data, id, inViewport, innerRef }) => {
  const { src, alt } = data;
  const wrapper = document.getElementById(id);
  let elRect = wrapper  ? wrapper.getBoundingClientRect() : null;
  const elPos = elRect ? elRect.y : 0;
  const classes = `characters-group ${inViewport || elPos < 0 ? 'shown' : ''}`;
  return <img src={src} alt={alt} className={classes} ref={innerRef}/>;
};

export default Character;