import React from 'react';

import './social-item.scss';

const SocialItem = ({data}) => {
  const { name, href, iconClass } = data;
  return(
    <li>
      <a href={href}>
        <i className={`icon ${iconClass}`}></i>
        <span className="sr-only">{name}</span>
      </a>
    </li>
  );
}

export default SocialItem;