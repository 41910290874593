import React from "react";

import "./info-block.scss";

const InfoBlock = ({ data }) => {
  const {
    image: { src, alt },
    title,
    title2,
    paragraph1,
    paragraph2,
    paragraph3,
    paragraph4,
  } = data;
  return (
    <div className="info-block">
      <div className="image-holder">
        <img src={src} alt={alt} />
      </div>
      <div className="content-holder">
        <p>{paragraph1}</p>
        <p>{paragraph2}</p>
        <h4>{title}</h4>
        <p>{paragraph3}</p>
        <h4>{title2}</h4>
        <p>{paragraph4}</p>
      </div>
    </div>
  );
};

export default InfoBlock;
