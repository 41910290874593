import React, {useState} from 'react';

import './backgrounds.scss';

import AsunaService from '../../services/asuna-service';

const Backgrounds = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { video, image } = new AsunaService().getBackgroundsData();
  const onVideoPlay = () => {
    setIsPlaying(true);
  }

  return(
    <div className={`bg-video-holder ${isPlaying ? 'is-playing' : null}`}>
      <video className='video-content' playsInline autoPlay loop muted onPlay={onVideoPlay} >
        <source src={video} type='video/mp4' />
      </video>
      <div className="background-image" style={{backgroundImage: `url(${image})`}}></div>
    </div>
    );
}

export default Backgrounds;