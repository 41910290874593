import React from 'react';
import Character from '../character';
import handleViewport from "react-in-viewport";

import './characters.scss';

import AsunaService from '../../services/asuna-service';

const Characters = () => {
  const data = new AsunaService().getCharactersData();
  const id = `id-${new Date().getTime()}`;
  const items = data.map((it, i) => {
    const { threshold } = it;
    const CharacterViewport = handleViewport(Character, {threshold});
    return <CharacterViewport data={it} key={`character-${i}`} id={id}/>
  });

  return (
    <div className="container container-fluid characters-container" id={id}>
      <div className="characters-holder">
        {items}
      </div>
    </div>
  );
};

export default Characters;