import React from "react";
import HeroMain from "../hero-main";
import Counter from "../counter";
import { Link } from "react-scroll";

import "./hero-section.scss";

const HeroSection = ({
  toggleCounter,
  showCounter,
  address,
  mintWhitelist,
  connectWallet,
  mint,
  didMint,
}) => {
  return (
    <section className="hero">
      <div className="container">
        {showCounter ? (
          <Counter
            address={address}
            mintWhitelist={mintWhitelist}
            mint={mint}
            didMint={didMint}
          />
        ) : (
          <HeroMain
            toggleCounter={toggleCounter}
            connectWallet={connectWallet}
          />
        )}
        <Link to="about" className="scroll-down" spy={true} smooth={true}>
          <span>learn more</span>
        </Link>
      </div>
    </section>
  );
};

export default HeroSection;
