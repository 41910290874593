import React from 'react';

import handleViewport from "react-in-viewport";
import RoadMapItem from '../roadmap-item';

import './roadmap-list.scss';

const RoadMapItemViewport = handleViewport(RoadMapItem);

const RoadMapList = ({data}) => {
  const items = data.map((it, i) => <RoadMapItemViewport data={it} key={`roadmap-item-${i}`}/>);
  return <div className="roadmap-holder">{ items }</div>
}

export default RoadMapList;