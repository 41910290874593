import React from 'react';
import AsunaService from '../../services/asuna-service';
import handleViewport from "react-in-viewport";
import TextBlock from '../text-block';
import RoadMapList from '../roadmap-list';

import './roadmap-section.scss';

const TextBlockViewport = handleViewport(TextBlock);

const RoadMapSection = () => {
  const { textBlock, roadMapList } = new AsunaService().getRoadMapSectionData();
  return(
    <section id="roadmap" className="roadmap-section">
      <div className="container">
        <TextBlockViewport data={textBlock}/>
        <RoadMapList data={roadMapList}/>
      </div>
    </section>
  );
}

export default RoadMapSection;