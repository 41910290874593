import React from 'react';

import './button.scss';

const Button = ({children, onClickHandler, customClass = null}) => {
  const classes = `btn ${customClass ? customClass : 'btn-decorated'}`
  return(
    <button className={classes} onClick={onClickHandler}>{children}</button>
  );
}

export default Button;