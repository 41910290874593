import React from 'react';
import TeamItem from '../team-item';

import './team-list.scss';

const TeamList = ({ data, enterCount, innerRef }) => {
  const classes = `card-wrap ${enterCount !== 0 ? 'shown' : ''}`;
  const items = data.map((it, i) => {
    return <TeamItem data={it} key={`team-item-${i}`}/>
  })
  return <div className={classes} ref={innerRef}>{items}</div>
}

export default TeamList;