import React from "react";
import SocialList from "../social-list";
import MailLink from "../mail-link";

import './footer-nav-item.scss';

const FooterNavItem = ({data}) => {
  const { title, social = null, email = null, titleLink = null } = data;
  return(
    <div className="nav-item">
      { titleLink ? <h5><a href={titleLink}>{title}</a></h5> : <h5>{title}</h5> }
      { social ? <SocialList data={social}/> : null }
      { email ? <MailLink data={email} /> : null }
    </div>
  );
}

export default FooterNavItem;