import React from 'react';

import BadgeItem from '../badge-item';

import './badge-list.scss';

const BadgeList = ({data}) => {
  const items = data.map((it, i) => {
    return <BadgeItem key={`badge-${i}`}>{it}</BadgeItem>
  });
  return <ul className="position-list">{ items }</ul>
}

export default BadgeList;