import React from 'react';

import './text-block.scss';

const TextBlock = ({data, enterCount, innerRef}) => {
  const classes = `text-block ${enterCount !== 0 ? 'shown' : ''}`;
  const { title, paragraph } = data;
  return(
    <div className={classes} ref={innerRef}>
      <h2>{title}</h2>
      <p>{paragraph}</p>
    </div>
    );
}

export default TextBlock;