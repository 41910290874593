import React from "react";

import "./roadmap-item.scss";

const RoadMapItem = ({ data, enterCount, innerRef }) => {
  const classes = `roadmap-item ${enterCount !== 0 ? "shown" : ""}`;
  const { progress, title, paragraph, addendum, imagesArr = null } = data;
  const images = !imagesArr
    ? null
    : imagesArr.map((it, i) => {
        const { src, alt } = it;
        return (
          <div className="img-wrap" key={`roadmap-image-${i}`}>
            <img src={src} alt={alt} />
          </div>
        );
      });

  return (
    <div className={classes} ref={innerRef}>
      {progress && <div className="roadmap-badge">{progress}</div>}
      <h3>{title}</h3>
      {!progress && <br />}
      <p>{paragraph}</p>
      <p>{addendum}</p>
      {images ? <div className="images-holder">{images}</div> : null}
    </div>
  );
};

export default RoadMapItem;
