import React, { useState, useEffect } from "react";
import AnchorNav from "../anchor-nav";
import SocialList from "../social-list";
import Button from "../button";
import BurgerButton from "../burger-button";

import "./header.scss";

import AsunaService from "../../services/asuna-service";

const Header = ({ toggleCounter, showCounter, address, connectWallet }) => {
  const [burgerMenuState, setBurgetMenuState] = useState(false);
  const { anchorNav, social, btnName } = new AsunaService().getHeaderData();

  const mobileNavClass = burgerMenuState ? "nav-active" : null;
  const counterTogglerName = showCounter ? "go back" : "check raffle";

  const onConnectWalletClick = async () => {
    await connectWallet();
    toggleCounter(!showCounter);
  };

  const onBurgerButtonClick = () => {
    setBurgetMenuState(!burgerMenuState);
  };

  const onAnchorLinkClick = () => {
    setBurgetMenuState(false);
  };

  return (
    <header className={mobileNavClass}>
      <div className="container">
        <div className="header-holder">
          <div className="nav-wrap">
            <BurgerButton onClickHandler={onBurgerButtonClick} />
            <div className="nav-drop">
              <AnchorNav
                data={anchorNav}
                onAnchorLinkClickHandler={onAnchorLinkClick}
              />
              <SocialList data={social} />
            </div>
          </div>
          {/* <Button onClickHandler={onConnectWalletClick}>
            {counterTogglerName}
          </Button> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
