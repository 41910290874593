import React, { useState, useEffect } from "react";
import handleViewport from "react-in-viewport";
import Header from "../header";
import Characters from "../characters";
import Hero from "../hero-section";
import AboutSection from "../about-section";
import Backgrounds from "../backgrounds";
import RoadMapSection from "../roadmap-section";
import TeamSection from "../team-section";
import Footer from "../footer";
import InfoSection from "../info-section";
import { ethers, BigNumber } from "ethers";
import SnackbarProvider, { useSnackbar } from "react-simple-snackbar";
import axios from "axios";
import "../app/app.scss";
import contractABI from "../../LivesOfAsuna.json";
import config from "../../config.json";

const ABI = contractABI.result;
const CONTRACT_ADDRESS = config.contractAddress;

const AboutSectionViewport = handleViewport(AboutSection);
// const CharactersViewport = handleViewport(Characters);

const SnackbarWrapper = () => {
  const [showCounter, setShowCounter] = useState(false);
  const [isLoggedIntoMetamask, setIsLoggedIntoMetamask] = useState(false);
  const [provider, setProvider] = useState(null);
  const [address, setAddress] = useState("");
  const [didLoad, setDidLoad] = React.useState(false);
  const [didFetch, setDidFetch] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [didMint, setDidMint] = useState(false);

  const refreshData = async () => {
    try {
      if (typeof window.web3 !== "undefined") {
        console.log("web3 is enabled");
        await initWallet();
        if (window.web3.currentProvider.isMetaMask === true) {
          console.log("MetaMask is active");
        } else {
          console.log("MetaMask is not available");
        }
      } else {
        console.log("web3 is not found");
      }

      setDidFetch(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!didLoad || !isLoggedIntoMetamask) {
      setDidLoad(true);
    }
  }, [didLoad, isLoggedIntoMetamask]);

  useEffect(() => {
    refreshData();
  }, [address]);

  // const contract = new ethers.Contract(contractAddress, contractABI);
  // const transaction =
  //   await contract.populateTransaction.registerAndMintForWhitelist(
  //     hash,
  //     signature,
  //     quantity,
  //     whitelistedAddress.count
  //   );

  const mint = async (quantity) => {
    try {
      const contract = new ethers.Contract(CONTRACT_ADDRESS, ABI);
      const params = await contract.populateTransaction.mintPublic(quantity);

      const price = BigNumber.from("80000000000000000");

      params.from = address;
      params.value = ethers.utils.hexValue(
        ethers.utils.parseUnits(price.mul(quantity).toString(), "wei")
      );

      const gas = await provider.estimateGas(params);
      params.gas = ethers.utils.hexValue(gas); // Scale this based on quantity

      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [params],
      });

      setDidMint(true);
    } catch (error) {
      console.log(error);
      if (error.code) {
        if (error.code !== 4001) {
          openSnackbar(error.code);
        }
      }
    }
  };

  const mintWhitelist = async (quantity) => {
    try {
      let mintResponse;

      mintResponse = await axios.get(
        "/api/getRawTransactionForWhitelistMinting",
        {
          params: {
            quantity,
            address,
          },
        }
      );

      const price = BigNumber.from("200000000000000000");

      const params = mintResponse.data;
      params.from = address;
      params.value = ethers.utils.hexValue(
        ethers.utils.parseUnits(price.mul(quantity).toString(), "wei")
      );

      const gas = await provider.estimateGas(params);
      params.gas = ethers.utils.hexValue(gas); // Scale this based on quantity

      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [params],
      });

      setDidMint(true);
    } catch (error) {
      console.log(error);
      if (error.code) {
        if (error.code !== 4001) {
          openSnackbar(error.code);
        }
      }
    }
  };

  const initWallet = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const accounts = await provider.listAccounts();

    window.ethereum.on("accountsChanged", function (accounts) {
      window.location.reload();
    });

    window.ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });

    if (accounts.length > 0) {
      const address = await provider.getSigner(0).getAddress();

      setIsLoggedIntoMetamask(true);
      setProvider(provider);
      setAddress(address);
    }
  };

  const connectWallet = async () => {
    await window.ethereum.enable();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const address = await provider.getSigner(0).getAddress();

    setIsLoggedIntoMetamask(true);
    setProvider(provider);
    setAddress(address);

    return address;
  };

  return (
    <div className="wrapper">
      <Backgrounds />
      <Header
        toggleCounter={setShowCounter}
        address={address}
        connectWallet={connectWallet}
        showCounter={showCounter}
      />
      <main className="main">
        <Hero
          connectWallet={connectWallet}
          toggleCounter={setShowCounter}
          showCounter={showCounter}
          address={address}
          mintWhitelist={mintWhitelist}
          mint={mint}
          didMint={didMint}
        />
        <div className="underlay">
          <AboutSectionViewport />
          <Characters />
          <RoadMapSection />
          <InfoSection />
          <TeamSection />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default SnackbarWrapper;
