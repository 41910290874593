import React from 'react';

import './title-custom.scss';

const TitleCustom = ({children}) => {
  return(
    <div className="title-decorated">
      <h2>{children}</h2>
    </div>
    );
}

export default TitleCustom;