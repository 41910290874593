import logo from "../assets/media/logo-white.png";
import charactersGroup1 from "../assets/media/group-1.png";
import charactersGroup2 from "../assets/media/group-2.png";
import charactersGroup3 from "../assets/media/group-3.png";
import charactersGroup4 from "../assets/media/group-4.png";
import charactersGroup5 from "../assets/media/group-5.png";
import charactersGroup6 from "../assets/media/group-6.png";
import charactersGroup7 from "../assets/media/group-7.png";
import charactersGroup8 from "../assets/media/group-8.png";
import charactersGroup9 from "../assets/media/group-9.png";
import roadmapImage from "../assets/media/image-2.svg";
import image1 from "../assets/media/previews.gif";
import teamImage from "../assets/media/image-2.svg";
import bgImage from "../assets/media/video-preview.jpg";
import bgVideo from "../assets/media/video.mp4";
import unrevealedRare from "../assets/media/unrevealed_rare.jpg";
import item1 from "../assets/media/item-1.jpg";
import item2 from "../assets/media/item-2-white.jpg";
import item3 from "../assets/media/item-3.jpg";
import zumi from "../assets/media/zumi.jpg";
import oksami from "../assets/media/oksami.jpg";
import hagglefish from "../assets/media/hagglefish.jpg";

const headerData = {
  anchorNav: ["about", "roadmap", "purchase", "team"],
  social: [
    {
      name: "name-1",
      href: "https://discord.gg/livesofasuna",
      iconClass: "icon-discord",
    },
    {
      name: "twitter",
      href: "https://twitter.com/LivesOfAsuna",
      iconClass: "icon-twitter",
    },
    {
      name: "name-2",
      href: "https://opensea.io/collection/livesofasuna",
      iconClass: "icon-opensea",
    },
  ],
  btnName: {
    mainHeroVisible: "connect wallet",
    counterVisible: "0.1080 ETH wallet address",
  },
};

const footerData = {
  logoImage: {
    src: logo,
    alt: "LivesOfAsuna",
  },
  footerNav: [
    {
      title: "Follow",
      social: [
        {
          name: "name-1",
          href: "https://discord.gg/livesofasuna",
          iconClass: "icon-discord",
        },
        {
          name: "twitter",
          href: "https://twitter.com/LivesOfAsuna",
          iconClass: "icon-twitter",
        },
        // {
        //   name: "name-2",
        //   href: "https://google.com",
        //   iconClass: "icon-opensea",
        // },
      ],
    },
    // {
    //   title: "Contact",
    //   email: "something@gmail.com",
    // },
    // {
    //   title: "FAQ",
    //   titleLink: "https://google.com",
    // },
  ],
  privacy: "© 2022 ZumiDraws",
};

const counterData = {
  title: "select quantity",
  paragraph1: "0.08 ETH per NFT",
  paragraph2: "You've minted 0/10 of your public sale limit",
  btnName: "mint",
};

const backgroundsData = {
  video: bgVideo,
  image: bgImage,
};

const heroSectionData = {
  logo: {
    src: logo,
    alt: "LivesOfAsuna",
  },
  btnName: "mint",
  paragraph:
    "Glimpse into 10,000 unique lives lived by Asuna through this collection of hand-drawn, anime-inspired NFTs.",
};

const aboutSectionData = {
  mainTitle: "From a demon-lord in one life...",
  infoBlock: {
    title: "Customize Asuna and shape her story",
    title2: "An accomplished idol in another life",
    image: {
      src: image1,
      alt: "image description",
    },
    paragraph1:
      "...to a mech pilot in another, Lives of Asuna is a collection conceived and designed by ZumiDraws that explores the many lives of our titular character as she exists across the vast multiverse.",
    paragraph2:
      "Totalling 10,000, each Asuna NFT is a unique combination of traits inspired by the rich world of anime, ranging from sci-fi and fantasy to slice-of-life.",
    paragraph3:
      "Asuna NFTs can be customized and shaped to your imagination with special items to alter her appearance. These items will be released after the initial launch and can be traded.",
    paragraph4:
      "Asuna can do it all -- including life as a musician across the multiverse. Hearing Asuna’s music will illuminate how imaginative your Asuna’s life can be. Endless possibilities and stories await. We will release Asuna’s first song prior to the launch of the collection.",
  },
};

const charactersData = [
  {
    src: charactersGroup1,
    alt: "image description",
    threshold: 0.2, // 0 - 1
  },
  {
    src: charactersGroup2,
    alt: "image description",
    threshold: 0.4,
  },
  {
    src: charactersGroup3,
    alt: "image description",
    threshold: 0.5,
  },
  {
    src: charactersGroup4,
    alt: "image description",
    threshold: 0.6,
  },
  {
    src: charactersGroup5,
    alt: "image description",
    threshold: 0.7,
  },
  {
    src: charactersGroup6,
    alt: "image description",
    threshold: 0.4,
  },
  {
    src: charactersGroup7,
    alt: "image description",
    threshold: 0.5,
  },
  {
    src: charactersGroup8,
    alt: "image description",
    threshold: 0.6,
  },
  {
    src: charactersGroup9,
    alt: "image description",
    threshold: 0.7,
  },
];

const roadMapSectionData = {
  textBlock: {
    title: "Roadmap",
    paragraph:
      "Below are the stages we currently have planned for Lives of Asuna. We have more ideas in the works that we hope to test and eventually implement as our community grows.",
  },
  roadMapList: [
    {
      progress: "1",
      title: "Launch",
      paragraph:
        "The Lives of Asuna NFT collection will be released January 2022.",
    },
    {
      progress: "2",
      title: "Special Asunas",
      paragraph:
        "A drop of 5 legendary Asuna NFTs that feature new traits not found in the rest of the collection for existing collectors.",
      imagesArr: [{ src: unrevealedRare, alt: "image description" }],
    },
    {
      progress: "3",
      title: "Outfit your Asuna with a rare item!",
      paragraph:
        "A batch of new items raffled to existing collectors to customize their Asuna NFTs. These items can be traded and used to replace or add new cosmetics to Asuna.",
      imagesArr: [
        { src: item1, alt: "image description" },
        { src: item2, alt: "image description" },
        { src: item3, alt: "image description" },
      ],
    },
    {
      progress: "4",
      title: "Explore the music of Asuna",
      paragraph:
        "The first song was just the beginning. Every musician dreams of releasing a project and Asuna is no different. Bridging the fantastic worlds of electronic music and Anime, community members will get to hear her mini-album first and vote on the title & artwork.",
    },
    {
      progress: "5",
      title: "Even more items for further customization",
      paragraph:
        "Alter the appearance of Asuna by collecting and equipping items.",
      addendum:
        "For the future that lies beyond this roadmap, we look forward to expanding the project along with our community to realize even greater ambitions for the Lives of Asuna universe!",
    },
  ],
};

const infoSectionData = {
  textBlock: {
    title: "Purchase",
    paragraph:
      "Lives of Asuna NFTs can be acquired in one of two stages, in a whitelisted presale and a public sale.",
  },
  roadMapList: [
    {
      progress: null,
      title: "Presale: January 27, 2:00 PM EST",
      paragraph: "• Minting price for the presale will be 0.08 ETH.",
      addendum:
        "• You can learn more about whitelisting opportunities in our Discord.",
    },
    {
      progress: null,
      title: "Public raffle results revealed: January 31st, 2:00 PM EST",
      paragraph:
        "• Winners will be able to mint at 0.2 ETH. The additional 0.12 ETH of this sale will be distributed into a community treasury.",
      addendum:
        "• We will add more winners every few hours until we are sold out.",
    },
    // {
    //   progress: "2",
    //   title: "Special Asunas",
    //   paragraph:
    //     "A drop of 5 legendary Asuna NFTs that feature new traits not found in the rest of the collection for existing collectors.",
    //   imagesArr: [{ src: unrevealedRare, alt: "image description" }],
    // },
    // {
    //   progress: "3",
    //   title: "Outfit your Asuna with a rare item!",
    //   paragraph:
    //     "A batch of new items raffled to existing collectors to customize their Asuna NFTs. These items can be traded and used to make existing Asunas more rare.",
    //   imagesArr: [
    //     { src: item1, alt: "image description" },
    //     { src: item2, alt: "image description" },
    //     { src: item3, alt: "image description" },
    //   ],
    // },
    // {
    //   progress: "4",
    //   title: "Explore the music of Asuna",
    //   paragraph:
    //     "The first song was just the beginning. Every musician dreams of releasing a project and Asuna is no different. Bridging the fantastic worlds of electronic music and Anime, community members will get to hear her mini-album first and vote on the title & artwork.",
    // },
    // {
    //   progress: "5",
    //   title: "Even more items for further customization",
    //   paragraph:
    //     "Alter the appearance of Asuna by collecting and equipping items. These items will vary in rarity and can be used to raise the value of an existing Asuna.",
    //   addendum:
    //     "For the future that lies beyond this roadmap, we look forward to expanding the project along with our community to realize even greater ambitions for the Lives of Asuna universe!",
    // },
  ],
};

const teamSectionData = {
  textBlock: {
    title: "Team",
    paragraph: "",
  },
  teamList: [
    {
      image: {
        src: zumi,
        alt: "image description",
      },
      title: "Zumi",
      badges: ["artist"],
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/ZumiDraws",
          iconClass: "icon-twitter",
        },
      ],
    },
    {
      image: {
        src: hagglefish,
        alt: "image description",
      },
      title: "Hagglefish",
      badges: ["artist"],
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/hagglebub",
          iconClass: "icon-twitter",
        },
      ],
    },
    {
      image: {
        src: oksami,
        alt: "image description",
      },
      title: "Oksami",
      badges: ["developer"],
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/itsoksami",
          iconClass: "icon-twitter",
        },
      ],
    },
  ],
};

export default class AsunaService {
  getHeaderData() {
    return headerData;
  }
  getFooterData() {
    return footerData;
  }
  getCounterData() {
    return counterData;
  }
  getBackgroundsData() {
    return backgroundsData;
  }
  getHeroSectionData() {
    return heroSectionData;
  }
  getAboutSectionData() {
    return aboutSectionData;
  }
  getCharactersData() {
    return charactersData;
  }
  getRoadMapSectionData() {
    return roadMapSectionData;
  }
  getInfoSectionData() {
    return infoSectionData;
  }
  getTeamSectionData() {
    return teamSectionData;
  }
}
