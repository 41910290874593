import React from "react";

import BadgeList from "../badge-list";
import SocialList from "../social-list";

import './team-item.scss';

const TeamItem = ({data}) => {
  const { image: {src, alt}, title, badges, social } = data;
  return(
    <article className="card-item">
      <div className="img-wrap">
        <img src={src} alt={alt}/>
      </div>
      <div className="card-body">
        <h3>{title}</h3>
        <BadgeList data={badges}/>
        <SocialList data={social}/>
      </div>
    </article>
  );
}

export default TeamItem;