import React from "react";
import Button from "../button";
import Logo from "../logo";
import Countdown from "react-countdown";

import "./hero-main.scss";
import AsunaService from "../../services/asuna-service";

const HeroMain = ({ toggleCounter, showCounter, address, connectWallet }) => {
  const { logo, btnName, paragraph } = new AsunaService().getHeroSectionData();

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <p className="medium">Waiting for sale to start...</p>;
    } else {
      // Render a countdown
      return (
        <span className="countdown">
          {days} days | {hours} hours | {minutes} min | {seconds} sec
        </span>
      );
    }
  };

  const onConnectWalletClick = async () => {
    console.log("click");
    await connectWallet();
    toggleCounter(!showCounter);
  };

  return (
    <div className="hero-content">
      <Logo data={logo} />
      {/* <Button
        customClass="btn-decorated-alt"
        onClickHandler={onConnectWalletClick}
      >
        view on opensea
      </Button> */}
      <br />
      <p className="medium">{paragraph}</p>
      <br />
      {/* <p className="medium">Public raffle results will be available in...</p>
      <br />
      <Countdown date={new Date(1643655600 * 1000)} renderer={renderer} /> */}
      {/* <br />
      <br />
      <p className="medium">Presale: January 27, 2022</p>
      <br />
      <p className="medium">Public Sale: January 29, 2022</p> */}
    </div>
  );
};

export default HeroMain;
